exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kandydaci-tsx": () => import("./../../../src/pages/kandydaci.tsx" /* webpackChunkName: "component---src-pages-kandydaci-tsx" */),
  "component---src-pages-kandydat-alina-krzyzaniak-switala-tsx": () => import("./../../../src/pages/kandydat/alina-krzyzaniak-switala.tsx" /* webpackChunkName: "component---src-pages-kandydat-alina-krzyzaniak-switala-tsx" */),
  "component---src-pages-kandydat-boguslaw-senska-tsx": () => import("./../../../src/pages/kandydat/boguslaw-senska.tsx" /* webpackChunkName: "component---src-pages-kandydat-boguslaw-senska-tsx" */),
  "component---src-pages-kandydat-danuta-szeliga-tsx": () => import("./../../../src/pages/kandydat/danuta-szeliga.tsx" /* webpackChunkName: "component---src-pages-kandydat-danuta-szeliga-tsx" */),
  "component---src-pages-kandydat-daria-nowak-tsx": () => import("./../../../src/pages/kandydat/daria-nowak.tsx" /* webpackChunkName: "component---src-pages-kandydat-daria-nowak-tsx" */),
  "component---src-pages-kandydat-jacek-bus-tsx": () => import("./../../../src/pages/kandydat/jacek-bus.tsx" /* webpackChunkName: "component---src-pages-kandydat-jacek-bus-tsx" */),
  "component---src-pages-kandydat-katarzyna-lisiecka-tsx": () => import("./../../../src/pages/kandydat/katarzyna-lisiecka.tsx" /* webpackChunkName: "component---src-pages-kandydat-katarzyna-lisiecka-tsx" */),
  "component---src-pages-kandydat-krzysztof-panek-tsx": () => import("./../../../src/pages/kandydat/krzysztof-panek.tsx" /* webpackChunkName: "component---src-pages-kandydat-krzysztof-panek-tsx" */),
  "component---src-pages-kandydat-marcin-ciegotura-tsx": () => import("./../../../src/pages/kandydat/marcin-ciegotura.tsx" /* webpackChunkName: "component---src-pages-kandydat-marcin-ciegotura-tsx" */),
  "component---src-pages-kandydat-mateusz-sznajdrowski-tsx": () => import("./../../../src/pages/kandydat/mateusz-sznajdrowski.tsx" /* webpackChunkName: "component---src-pages-kandydat-mateusz-sznajdrowski-tsx" */),
  "component---src-pages-kandydat-michal-piotrowski-tsx": () => import("./../../../src/pages/kandydat/michal-piotrowski.tsx" /* webpackChunkName: "component---src-pages-kandydat-michal-piotrowski-tsx" */),
  "component---src-pages-kandydat-przemyslaw-siekierski-tsx": () => import("./../../../src/pages/kandydat/przemyslaw-siekierski.tsx" /* webpackChunkName: "component---src-pages-kandydat-przemyslaw-siekierski-tsx" */),
  "component---src-pages-kandydat-roza-trela-tsx": () => import("./../../../src/pages/kandydat/roza-trela.tsx" /* webpackChunkName: "component---src-pages-kandydat-roza-trela-tsx" */),
  "component---src-pages-kandydat-tomasz-lewandowski-tsx": () => import("./../../../src/pages/kandydat/tomasz-lewandowski.tsx" /* webpackChunkName: "component---src-pages-kandydat-tomasz-lewandowski-tsx" */),
  "component---src-pages-kandydat-weronika-wasowska-tsx": () => import("./../../../src/pages/kandydat/weronika-wasowska.tsx" /* webpackChunkName: "component---src-pages-kandydat-weronika-wasowska-tsx" */),
  "component---src-pages-kandydat-wieslaw-kubkowski-tsx": () => import("./../../../src/pages/kandydat/wieslaw-kubkowski.tsx" /* webpackChunkName: "component---src-pages-kandydat-wieslaw-kubkowski-tsx" */),
  "component---src-pages-kandydat-wiktoria-bednarek-tsx": () => import("./../../../src/pages/kandydat/wiktoria-bednarek.tsx" /* webpackChunkName: "component---src-pages-kandydat-wiktoria-bednarek-tsx" */),
  "component---src-pages-o-komitecie-tsx": () => import("./../../../src/pages/o-komitecie.tsx" /* webpackChunkName: "component---src-pages-o-komitecie-tsx" */),
  "component---src-pages-rejestr-wplat-tsx": () => import("./../../../src/pages/rejestr-wplat.tsx" /* webpackChunkName: "component---src-pages-rejestr-wplat-tsx" */)
}

